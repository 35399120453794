import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import './Gallery.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import {slides1, slides2} from './GalleryData.js';

import Captions from 'yet-another-react-lightbox/plugins/captions';

const Gallery = () => {
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState(slides1);
    const [projects, setProjects] = useState([]);

    const openLightbox = (projectIndex) => {
        let theSlides = projects[projectIndex].photos;
        console.log( "Selected Slides: ", theSlides );

        setSlides(theSlides); 
        setOpen(true);
    };

    useEffect(() => {
        fetchProjects();
      }, []);
    
      const fetchProjects = async () => {
        try {
          const response = await fetch('/projects/index.json');
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          const data = await response.json();
        //   const formattedProjects = data.projects.map(project => ({
        //     ...project,
        //     images: project.images.map(image => ({
        //       src: `/gallery/projects/${project.name.toLowerCase().replace(/ /g, '')}/${image}`,
        //       alt: `${project.name} ${image}`
        //     }))
        //   }));
          setProjects(data);
          console.log("Projects are: ", data);
        } catch (error) {
          console.error('Error fetching project data:', error);
        }
      };
    
    return (
        <section id="gallery">
        <Header />
        <div className="container">
            <h2>Photo Gallery</h2>
            { projects &&
                <div className="project-thumbnails">
                {projects.map((project, projectIndex) => (
                    <div key={projectIndex} className="project-thumbnail">
                    <img
                        src={project.thumbnailSrc}
                        alt={project.caption}
                        projectJson={project.projectPath}
                        onClick={() => openLightbox(projectIndex, 0)}
                    />
                    <div>
                        <p>{project.name}</p>
                        <div className="info-button-container">
                        <button className="info-button" title="Info">i</button>
                        <div className="info-panel">
                            <p>{project.description}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                ))}

                { open &&
                    <Lightbox plugins={[Captions]} open={open} slides={slides} close={() => setOpen(false)}/>
                }
            </div>
            } 
        </div>
        </section>
    );
};

export default Gallery;
