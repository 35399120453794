import React, { useEffect, useState } from 'react';

const Welcome = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('data/welcome.txt')
            .then(response => response.text())
            .then(text => setContent(text))
            .catch(error => console.error('Error fetching welcome.txt:', error));
    }, []);

    return (
        <section id="about">
            <div className="container">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </section>
    );
};

export default Welcome;
