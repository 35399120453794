
const slides1 = [
    {
        src: '/gallery/IMG_2281.jpeg',
        title: 'Siding Installation',
        description: 'Large project installing complicated commercial siding on a residential building - the first in the US!',
    },
    {
        src: '/gallery/IMG_8236.jpeg',
        title: 'Siding Installation',
        description: 'Large project installing complicated commercial siding on a residential building - the first in the US!',
    },
    {
        src: '/gallery/IMG_8931.jpeg',
        title: 'Siding Installation',
        description: 'Large project installing complicated commercial siding on a residential building - the first in the US!',
    },
];

const slides2 = [
    {
        src: '/gallery/IMG_6381.jpeg',
        title: 'Cedar roof installation',
        description: 'Total pain in the neck - but totally worth it',
    },
    {
        src: '/gallery/IMG_1113.jpeg',
        title: 'Dropped shower installation',
        description: 'We droppped the floor in this small bathroom to accomodate a new curbless shower and adjoining tub',
    },
    {
        src: '/gallery/IMG_9382.jpeg',
        title: 'Stripped to studs',
        description: 'Full remodel of a Saratoga mountain house',
    },
];

export {slides1};
export {slides2};
