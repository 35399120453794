
import Header from '../components/Header/Header';

export default function NoPage() {
    return (
        <div>
            <Header />
            <h2>Error 404:  Not Found</h2>
        </div>
    )
}
