import React, { useEffect, useState } from 'react';
import "./About.css";

const About = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('data/about-us.txt')
            .then(response => response.text())
            .then(text => setContent(text))
            .catch(error => console.error('Error fetching about-us.txt:', error));
    }, []);

    return (
        <section id="about">
            <div className="container">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </section>
    );
};

export default About;
