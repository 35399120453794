
import Header from '../../components/Header/Header';
import About from '../../components/About/About';
import Services from '../../components/Services';
import Welcome from '../../components/Welcome';
import Footer from '../../components/Footer';
import './Home.css';

export default function Home() {
    return (
        <div>
            <Header />
            <section id="home">
                <div className="container">
                    <div id="welcome-content"></div>
                </div>
            </section>
            <Welcome />
            <Services />
            <About />
            <Footer />

        </div>
    )
}
