import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles.css';

import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//       <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
)

// // Add scroll event listener
window.addEventListener('scroll', function() {
  const header = document.querySelector('header');
  const logo = document.querySelector('header .logo img');
  const heading = document.querySelector('header h1');

  // if (window.scrollY > 50) {
  //     header.classList.add('shrink');
  //     logo.classList.add('shrink');
  //     heading.classList.add('shrink');
  // } else {
  //     header.classList.remove('shrink');
  //     logo.classList.remove('shrink');
  //     heading.classList.remove('shrink');
  // }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
