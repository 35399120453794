import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
        <div className="container">
            <p>&copy; 2024 Sequoia Peak Construction. All rights reserved.</p>
        </div>
    </footer>
   );
};

export default Footer;
