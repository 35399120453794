import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    return (
        <header>
        <div className="container">
            <div className="logo">
                <img src="/images/logov2.png" alt="Sequoia Peak Construction Logo"/>
            </div>

            {/* <h2 className="animated-text">Building Excellence</h2> */}
            <h2>Building Excellence</h2>
        </div>
        <div>
            <nav className="navbar">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/gallery">Gallery</Link></li>
                        {/* <li><Link to="/testimonials">Testimonials</Link></li> */}
                    </ul>
                </nav>
        </div>
    </header>

   );
};

export default Header;
