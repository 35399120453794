import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import Header from '../../components/Header/Header';
import { FaCheckCircle } from 'react-icons/fa'; // Import the check mark icon

const ContactUs = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        service: '',
        projectDescription: '',
    });
    
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        emailjs.init('buANXYANmevk3AXk8');  // Initialize EmailJS with your public key
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        emailjs.send(
            'service_tlh8cxj',
            'template_68ofs67',
            formData
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            // console.log('Form data sent:', formData);
            setIsSubmitted(true);
        }).catch((err) => {
            console.error('FAILED...', err);
        });
    };

    return (
        <section id="contactus">
        <Header />
        <h2>Contact Us</h2>
        <div className="contact-form-container">
            { isSubmitted ? (
                <div className="contact-success-box">
                <FaCheckCircle size={100} color="green" />
                <p>Thank You! </p>
                <p>Your submission has been received. Someone will be in contact with you shortly.</p>
            </div>
            ) : (
                <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="fullName" className="form-label">Full Name:</label>
                    <input 
                        type="text" 
                        name="fullName" 
                        value={formData.fullName} 
                        onChange={handleChange} 
                        className="form-input" 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input 
                        type="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        className="form-input" 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="service" className="form-label">Service:</label>
                    <select 
                        name="service" 
                        value={formData.service} 
                        onChange={handleChange} 
                        className="form-input" 
                        required
                    >
                        <option value="">Select a service</option>
                        <option value="Bathroom Remodel">Bathroom Remodel</option>
                        <option value="Kitchen Remodel">Kitchen Remodel</option>
                        <option value="Custom Woodworking / Carpentry">Custom Woodworking</option>
                        <option value="Room Addition">Room Addition</option>
                        <option value="Deck or outside structure build">Deck or outside structure build</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="projectDescription" className="form-label">Project Description:</label>
                    <textarea 
                        name="projectDescription" 
                        value={formData.projectDescription} 
                        onChange={handleChange} 
                        className="form-input" 
                        required 
                    />
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
            )
            }

            <div className="contact-image">
                <img 
                    src="images/contactpic.png" 
                    alt="Contact Us" 
                />
            </div>
        </div>
        </section>
    );
};

export default ContactUs;