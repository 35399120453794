import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import './testimonials.css';

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        fetch('data/testimonials.json')
            .then(response => response.json())
            .then(data => setTestimonials(data.testimonials))
            .catch(error => console.error('Error fetching projects:', error));
    }, []);

    return (
        <section id="testimonials">
        <Header />
         
        {testimonials.map((testimonial, index) => (
        <div className="testimonial">
          <p><em>{testimonial.text}</em></p>
          <p>--  {testimonial.author}</p>
        </div>
      ))}

        </section>
    );
};

export default Testimonials;
